@import url('https://fonts.googleapis.com/css?family=Courgette');

html,
body,
#lifepath-widget {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}

#lifepath-widget {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: linear-gradient(0deg, #f9e3bb, #c3e9ff);
  font-family: 'Courgette', cursive;
  padding: 20px;
}
